define(['siteObj'], function(siteObj) {
  /*
  * tracking configuration
  * ======================
  *
  * each config item contains two objects:
  * trigger: specifies the event that will trigger a track event to be published
  *          the fields will vary depending on the type of trigger e.g. DOM selector, event names etc.
  * publish: specifies the tracking event to be published, types of event will have default data attached
  *          e.g. parameters added to a published event or the DOM element that triggers a DOM event
  *          metrics allows additional data to be attached to the published event,
  *          these will be generated by the metrics component
  **/
  const Config = [
    {
      key: 'trackingRecord',
      trigger: {
        type: 'event',
        event: 'tracking/record',
      },
      publish: {
        event: 'recordEvent',
        metrics: ['meta']
      }
    },
    {
      key: 'trackingWidget',
      trigger: {
        type: 'event',
        event: 'tracking/widget',
      },
      publish: {
        event: 'widgetEvent',
        metrics: ['meta', 'experiments', 'widget']
      }
    },
    {
      key: 'columboEvent',
      trigger: {
        type: 'event',
        event: 'columbo/track'
      },
      publish: {
        event: 'columboChaseTracking',
        metrics: ['ux']
      }
    },
    {
      key: 'trackingPyop',
      trigger: {
        type: 'event',
        event: 'tracking/pyop',
      },
      publish: {
        event: 'pyopEvent',
        metrics: ['meta']
      }
    },
    {
      key: 'trackingEcommerce',
      trigger: {
        type: 'event',
        event: 'tracking/ecommerce',
      },
      publish: {
        event: 'ecommerceEvent',
        metrics: ['meta']
      }
    },
    {
      key: 'pageLoad',
      trigger: {
        type: 'page',
        event: 'load'
      },
      publish: {
        event: 'pageLoad',
        metrics: ['meta', 'experiments', 'performance', 'products', 'reporting']
      }
    },
    {
      key: 'hashChange',
      trigger: {
        type: 'page',
        event: 'hashchange'
      },
      publish: {
        event: 'hashchange',
        metrics: ['meta', 'performance']
      }
    },
    {
      key: 'basketItemAdded',
      trigger: {
        type: 'event',
        event: 'BasketItemAdded'
      },
      publish: {
        event: 'basket-item-added',
        metrics: ['meta', 'basket']
      }
    },
    {
      key: 'componentViewed',
      trigger: {
        type: 'componentViewed',
      },
      publish: {
        event: 'component-viewed',
        metrics: ['ux']
      }
    },
    {
      key: 'componentClicked',
      trigger: {
        type: 'componentClicked',
      },
      publish: {
        event: 'component-clicked',
        metrics: ['ux']
      }
    },
    {
      key: 'componentFocused',
      trigger: {
        type: 'componentFocused',
      },
      publish: {
        event: 'component-focused',
        metrics: ['ux']
      }
    },
    {
      key: 'componentHovered',
      trigger: {
        type: 'componentHovered',
      },
      publish: {
        event: 'component-hovered',
        metrics: ['ux']
      }
    },
    {
      key: 'quickBuyPopup',
      trigger: {
        type: 'spy',
        className: '.addedToBasketModal_container'
      },
      publish: {
        event: 'spy'
      }
    }
  ];

  let webVitalsEventObject = {
    key: 'pageBeforeUnload',
    trigger: {
      type: 'page',
      event: 'beforeunload'
    },
    publish: {
      event: 'pageBeforeUnload',
      metrics: ['meta', 'web-vitals']
    }
  };

  if (siteObj.features && siteObj.features.usePageHideForWebVitals) {
    webVitalsEventObject = {
      key: 'pageHide',
      trigger: {
        type: 'page',
        event: 'onpagehide' in self ? 'pagehide' : 'unload',
      },
      publish: {
        event: 'pageHide',
        metrics: ['meta', 'web-vitals']
      }
    };
  } else if (siteObj.features && siteObj.features.combinePerformanceMetrics) {
    webVitalsEventObject = {
      key: 'pageHide',
      trigger: {
        type: 'page',
        event: 'onpagehide' in self ? 'pagehide' : 'unload',
      },
      publish: {
        event: 'pageHide',
        metrics: ['meta', 'experiments', 'performance', 'web-vitals']
      }
    };
  }

  Config.push(webVitalsEventObject);

  return Config;
});
